import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Title from '../../components/title';
import Video from '../../components/Video';
import { BuyTickets } from '../../components/Typo';

import { Wrapper, VideoPlaceholder } from './Why.styles';

const QUERY = graphql`
  fragment ReasonImage on File {
    childImageSharp {
      fluid(
        quality: 80,
        maxWidth: 320,
        toFormat: JPG,
        sizes: "(max-width: 768px) calc(50vw - 30px), 25vw"
      ) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  query {
    reason1: file(relativePath: { eq: "why/raison1.png" }) {
      ...ReasonImage
    }
    reason2: file(relativePath: { eq: "why/raison2.png" }) {
      ...ReasonImage
    }
    reason3: file(relativePath: { eq: "why/raison3.png" }) {
      ...ReasonImage
    }
    reason4: file(relativePath: { eq: "why/raison4.png" }) {
      ...ReasonImage
    }
    reason5: file(relativePath: { eq: "why/raison5.png" }) {
      ...ReasonImage
    }
    reason6: file(relativePath: { eq: "why/raison6.png" }) {
      ...ReasonImage
    }
    reason7: file(relativePath: { eq: "why/raison7.png" }) {
      ...ReasonImage
    }
    reason8: file(relativePath: { eq: "why/raison8.png" }) {
      ...ReasonImage
    }
    reason9: file(relativePath: { eq: "why/raison9.png" }) {
      ...ReasonImage
    }
    reason10: file(relativePath: { eq: "why/raison10.png" }) {
      ...ReasonImage
    }
  }
`;

const Comp = (props) => {
  const { intl } = props;
  const data = useStaticQuery(QUERY);

  return (
    <Wrapper id="sec-agenda">
      <div className="container">
        <Title title={intl.formatMessage({ id: 'reasons.title' })} />
        <div className="grid">
          <div className="col-2 description">
            <strong><FormattedMessage id="reasons.subtitle" /></strong>
            <p>
              <FormattedMessage id="reasons.description" />
            </p>
            <div className="footer">
              <BuyTickets
                className="medium"
              />
            </div>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason1.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.1`} /></h4>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason2.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.2`} /></h4>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason5.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.5`} /></h4>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason4.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.4`} /></h4>
          </div>
          <div className="col-1">
            <VideoPlaceholder>
              <Video
                className="video"
                video="392664263"
                autopause={false}
                autoplay
                background
                muted
                loop={true}
                controls={false}
              />
            </VideoPlaceholder>
            <h4><FormattedMessage id={`reasons.3`} /></h4>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason6.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.6`} /></h4>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason7.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.7`} /></h4>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason8.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.8`} /></h4>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason9.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.9`} /></h4>
          </div>
          <div className="col-1">
            <Img
              fluid={data.reason10.childImageSharp.fluid}
            />
            <h4><FormattedMessage id={`reasons.10`} /></h4>
          </div>
        </div>

      </div>
    </Wrapper>
  );
};

export default injectIntl(Comp);
