import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';
import posed from 'react-pose';
import { MorphReplace } from 'react-svg-morph';

import Title from '../../components/title';
import Video from '../../components/Video';

import { BuyTickets } from '../../components/Typo';

import { SoundOn, SoundOff } from '../../components/icons/sound';
import { scrollTo } from '../../utils';

import {
  Wrapper,
  VideoPlaceholder,
  ContainerLaptop,
  ContainerMobile
} from './Header.styles';

const QUERY = graphql`
  query {
    motorland: file(relativePath: { eq: "cover/motorland.png" }) {
      childImageSharp {
        fixed(quality: 80, height: 90, toFormat: PNG) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    motorlandMobile: file(relativePath: { eq: "cover/motorland-mobile.png" }) {
      childImageSharp {
        fixed(quality: 80, height: 60, toFormat: PNG) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    video: file(relativePath: { eq: "cover/video.png" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Item = posed.li({
  show: {
    opacity: 1,
    y: '0%',
    x: '0'
 },
  hide: {
    opacity: 0,
    y: '100%',
    x: '-25%'
  },
  initialPose: 'hide'
});

const GroupsDesktop = posed.ul({
  show: {
    delayChildren: 1000,
    staggerChildren: 300
  }
});


const featuresNumber = 6;

const scrollToOffer = () => scrollTo("#sec-offers", 140);

const Comp = (props) => {
  const { intl } = props;
  const data = useStaticQuery(QUERY);

  // const [videoReady, setVideoReady] = useState(false);
  // const [muted, setMuted] = useState(true);

  return (
    <Wrapper>
      <VideoPlaceholder>
        <Img
          className="loading"
          fluid={data.video.childImageSharp.fluid}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        {/* <Video
          className="video"
          sensorClassName="absolute"
          readyClassName="show"
          video="392664263"
          autopause={false}
          autoplay
          background
          responsive
          controls={false}
          loop={true}
          muted={true}
          volume={muted ? 0 : 0.5}
          onReady={() => {
            setVideoReady(true);
          }}
        /> */}
      </VideoPlaceholder>
      <ContainerMobile className="container">
        <ul
          className="points"
        >
          {[...Array(featuresNumber).keys()].map((i) => (
            <li
              key={i}
              pose="stale"
              idx={i}
              onClick={scrollToOffer}
            >
              <div className="bbox">
                <span><FormattedMessage id={`header.activities.${i}`} /></span>
              </div>
            </li>
          ))}
        </ul>
        <div className="text">
          <Title
            title={intl.formatMessage({
              id: 'cover.title',
              values: {
                br: () => null,
              }
            })}
          />
          <Title
            title={intl.formatMessage({
              id: 'cover.description',
              values: {
                br: () => null,
              }
            })}
          />
          <Img
            className="motorland"
            fixed={data.motorlandMobile.childImageSharp.fixed}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
          <div className="dates" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: 'common.dates' }) }} />
          <div className="buy-button">
            <BuyTickets
              className="button medium"
            />
          </div>
        </div>
      </ContainerMobile>
      <ContainerLaptop className="container">
        <div className="title-container">
          <Title
            title={intl.formatMessage({ id: 'cover.title' })}
          />
          <Title
            className="bbox"
            title={intl.formatMessage({ id: 'cover.description' })}
          />
          <div className="title-bottom">
            <Img
              fixed={{
                ...data.motorland.childImageSharp.fixed,
                media: `(min-width: 768px)`,
              }}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'top left',
              }}
              loading="eager"
            />
            <div className="dates" dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: 'common.dates' }) }} />
          </div>
        </div>
        <div className="buy-button">
          <BuyTickets
            className="button skew medium"
          />
        </div>
        {/* <div className="sound">
          <div
            className="toggle bbox"
            role="button"
            tabIndex={0}
            onClick={() => {
              setMuted(!muted);
            }}
          >
            <MorphReplace
              width={30}
              height={24}
              rotation="none"
            >
              {muted ? (
                <SoundOff
                  width="30px"
                  key="off"
                />
              ) : (
                <SoundOn
                  width="30px"
                  key="on"
                />
              )}
            </MorphReplace>
          </div>
        </div> */}
        <GroupsDesktop
          className="points"
          pose="show"
          // pose={videoReady ? 'show' : 'hide'}
        >
          {[...Array(featuresNumber).keys()].map((i) => (
            <Item
              key={i}
              pose="stale"
              onClick={scrollToOffer}
            >
              <div className="bbox">
                <span><FormattedMessage id={`header.activities.${i}`} /></span>
              </div>
            </Item>
          ))}
        </GroupsDesktop>
      </ContainerLaptop>
    </Wrapper>
  );
};

export default injectIntl(Comp);
