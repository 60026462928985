import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 40px 0;

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -10px;
    font-weight: lighter;

    [class*="col"] {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding: 10px;
    }

    .col-2 {
      display: flex;
      flex-direction: column;
      width: 50%;
      font-size: 18px;
    }

    .description {
      display: none;
    }

    .footer {
      margin-top: auto;
    }

    .col-1 {
      width: 50%;
    }

    .gatsby-image-wrapper {
      width: 100%;
    }

    h4 {
      text-transform: uppercase;
      font-size: 10px;
      margin: 10px 0 5px 0;
      line-height: 1.2em;
    }
  }

  @media ${device.tablet} {
    .grid {
      margin: 20px -10px;
  
      .description {
        display: flex;
        font-size: 12px;
      }

      [class*="col"] {
        padding: 10px;
      }

      .col-1 {
        width: 25%;
      }

      h4 {
        font-size: 10px;
        margin: 10px 0 0 0;
        line-height: 1.3em;
      }
    }
  }

  @media ${device.laptop} {
    .grid {
      margin: 30px -10px;
    
      .description {
        font-size: 16px;
      }

      h4 {
        font-size: 14px;
      }
    }
  }

  @media ${device.laptopM} {
    .grid {
      [class*="col"] {
        padding: 20px;
      }

      .description {
        font-size: 18px;
      }
    }
  }

`;

export const VideoPlaceholder = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 71.93%;
  overflow: hidden;
  pointer-events: none;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transform: translateX(-25%);

    iframe {
      height: 100%!important;
      width: 170%!important;
    }
  }
`;