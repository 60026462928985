import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import Header from "../screens/homepage/Header";
import Event from "../screens/homepage/Event";
import Pictures from "../screens/homepage/Pictures";
import LastYear from "../screens/homepage/LastYear";
import Why from "../screens/homepage/Why";
import Motorland from "../screens/homepage/Motorland";
import Offers from "../screens/homepage/Offers";
import Rent from "../screens/homepage/Rent";
// import Counter from "../screens/homepage/Counter";
import Infos from "../screens/homepage/Infos";

import SEO from "../components/seo";

const IndexPage = ({ intl }) => (
  <Layout>
    <SEO
      description={intl
        .formatMessage({
          id: "event.description",
        })
        .replace(/(<([^>]+)>)/gi, "")}
      title={`Ipone Days - ${intl
        .formatMessage({ id: "common.dates" })
        .replace(/<[^>]*>?/gm, "")}`}
      lang={intl.locale}
    />
    <Header />
    <Event />
    <Pictures />
    <LastYear />
    <Why />
    <Motorland />
    <Offers />
    <Rent />
    {/* <Counter /> */}
    <Infos />
  </Layout>
);

export default injectIntl(IndexPage);
