import React, { useState, useRef } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import Title from '../../components/title';
import { Icon } from '../../components/icons';
import HandIcon from '../../images/icons/hand.svg';
import ArrowDown from '../../images/icons/arrow-down.svg';

import { Wrapper } from './Infos.styles';
import { BuyTickets } from '../../components/Typo';

const Accordion = injectIntl((props) => {
  const content = useRef(null);

  const [state, setState] = useState({
    show: false,
    height: 0,
  });

  return (
    <div
      className={classnames('accordion-section', {
        show: state.show
      })}
    >
      <div
        className="header"
        role="button"
        tabIndex={0}
        onClick={() => setState({ show: !state.show, height: content.current.scrollHeight })}
      >
        <Icon
          src={HandIcon}
          width="28px"
        />
        <h4>{props.title}</h4>

        <Icon
          className="right"
          src={ArrowDown}
          height="23px"
        />
      </div>
      <div
        className="content-wrapper"
        ref={content}
        style={{ maxHeight: `${state.show ? state.height : 0}px` }}
      >
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
      
    </div>
  );
});

const Comp = (props) => {
  const { intl } = props;

  return (
    <Wrapper id="sec-infos">
      <div className="container">
        <Title title={intl.formatMessage({ id: 'information.title' })} />
        <div className="faq">
          <Accordion
            title={intl.formatMessage({ id: 'information.goto.title' })}
            content={intl.formatMessage({ id: 'information.goto.description' })}
          />
          <Accordion
            title={intl.formatMessage({ id: 'information.host.title' })}
            content={intl.formatMessage({ id: 'information.host.description' })}
          />
          <Accordion
            title={intl.formatMessage({ id: 'information.catering.title' })}
            content={intl.formatMessage({ id: 'information.catering.description' })}
          />
          <Accordion
            title={intl.formatMessage({ id: 'information.services.title' })}
            content={intl.formatMessage({ id: 'information.services.description' })}
          />
        </div>
        <div className="footer">
          <BuyTickets />
        </div>
      </div>
    </Wrapper>
  );
};

export default injectIntl(Comp);
