import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Title from '../../components/title';
import { BuyTickets } from '../../components/Typo';
import { scrollTo } from '../../utils';

import { Icon } from '../../components/icons';

import Calendar from '../../images/icons/calendar.svg';

import { Wrapper } from './Event.styles';

const QUERY = graphql`
  query {
    bikes: file(relativePath: { eq: "events/bikes.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 960, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bikesMobile: file(relativePath: { eq: "events/bikes-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 960, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const scrollToOffer = () => scrollTo("#sec-offers", 140);

const Comp = (props) => {
  const { intl } = props;
  const data = useStaticQuery(QUERY);
  
  return (
    <Wrapper id="sec-event">
      <div
        className="illustration-mobile"
        onClick={scrollToOffer}
      >
        <Img
          fluid={data.bikesMobile.childImageSharp.fluid}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </div>
      <div className="container">
        <div className="text-container">
          <Title title={intl.formatMessage({ id: 'event.title' })} />
          <FormattedMessage
            id="event.description"
            values={{
              p: (...chunks) => <p>{chunks}</p>,
              bold: (...chunks) => <strong>{chunks}</strong>,
            }}
          />
          <div className="dates">
            <Icon
              src={Calendar}
              width="28px"
            />
            <span dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: 'common.dates' }) }} />
          </div>
          <BuyTickets
            className="medium"
          />
        </div>
        <div
          className="illustration"
          onClick={scrollToOffer}
        >
          <Img
            fluid={data.bikes.childImageSharp.fluid}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'top right',
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default injectIntl(Comp);
