import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  position: relative;
  margin-top: 80px;
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-bottom: 60px;
    margin-top: 90px;
  }

  .points {
    display: flex;
    margin: 0 -10px;
    padding: 0;
    
    li {
      list-style: none;
      flex: 1;
      text-align: center;
      cursor: pointer;      

      .bbox {
        display: block;
        padding: 7px;
        margin: 0 5px;
        user-select: none;
      }

      span {
        font-size: 16px;
      }
    }
  }

  @media ${device.laptopM} {
    .points {
      li {
        span {
          font-size: 18px;
        }
      }
    }
  }
`;

export const ContainerMobile = styled.div`

  @media ${device.laptop} {
    display: none;
  }

  .points {
    flex-wrap: wrap;
    margin-top: -20px;
    margin-bottom: 25px;
    overflow: hidden;
    /* justify-content: center; */

    li {
      flex: 0 0 calc(50% - 10px);
      width: calc(50% - 10px);
      margin-bottom: 7px;

      &:nth-child(1) {
        padding-left: 20px;
      }

      &:nth-child(3) {
        padding-left: 10px;
      }

      .bbox {
        padding: 5px;
      }

      span {
        font-size: 15px;
      }
    }
  }

  .text {
    text-align: center;

    .title {
      text-align: center;
      margin: 3px 0;
    }

    .motorland {
      display: block !important;
      margin: 15px auto;
    }

    .dates {
      display: inline-block;
      font-size: 16px;
      font-weight: 900;
      font-style: italic;
      margin: 10px;
      text-transform: uppercase;
    }
  }

  .buy-button {
    margin-top: 10px;
  }
`;

export const ContainerLaptop = styled.div`
  display: none;

  @media ${device.laptop} {
    display: block;
  }

  &.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .title-container {
    position: absolute;
    top: 20%;
    left: 20px;
    right: 20px;
    color: #FFF;

    .title {
      letter-spacing: -0.02em;
      font-weight: 900;
      margin: 0;
      padding: 7px 15px;
    }

    .title-bottom {
      position: absolute;
      top: 100%;
      left: -25px;
      display: flex;

      .gatsby-image-wrapper {
        margin-top: -18px;
      }

      .dates {
        display: inline-block;
        font-size: 22px;
        font-weight: 900;
        font-style: italic;
        margin: 10px;
        text-transform: uppercase;
      }
    }
  }

  .buy-button {
    position: absolute;
    top: 50%;
    left: 40px;
  }

  .sound {
    position: absolute;
    bottom: 20%;
    left: 40px;
    user-select: none;
      
    .toggle {
      padding: 8px 15px;
      line-height: 1em;
      cursor: pointer;
      
      svg {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .points {
    position: absolute;
    bottom: 20px;
    left: 15px;
    right: 15px;
  }
`;

export const VideoPlaceholder = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 42.5%;
  background: center center no-repeat;
  background-size: cover;

  .loading  {
    position: absolute!important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: 2s;
  
    &.show {
      opacity: 1;
    }
  }
`;