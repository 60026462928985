import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Title from '../../components/title';
import { Icon } from '../../components/icons';

import Track from '../../images/icons/track.svg';
import Poi from '../../images/icons/poi.svg';

import { Wrapper } from './Motorland.styles';

const QUERY = graphql`
  query {
    track: file(relativePath: { eq: "motorland/track.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    trackMobile: file(relativePath: { eq: "motorland/track-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 768, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;


const Comp = (props) => {
  const { intl } = props;
  const data = useStaticQuery(QUERY);
  
  return (
    <Wrapper>
      <Img
        className="background-track"
        fluid={[
          data.trackMobile.childImageSharp.fluid, 
          {
            ...data.track.childImageSharp.fluid,
            media: `(min-width: 768px)`,
          }
        ]}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
      <div className="container">
        <div className="title-container">
          <Title
            className="bbox"
            title={intl.formatMessage({ id: 'tracks.title1'})}
          />
          <Title
            className="bbox"
            title={intl.formatMessage({ id: 'tracks.title2'})}
          />
        </div>
        <div className="track-container">
          <Icon
            src={Track}
            height="210px"
          />
          <div className="row">
            <Icon
              src={Poi}
              width="17px"
            />
            <address>
              <strong><FormattedMessage id="tracks.motorland" /></strong>
              <br />
              <FormattedMessage id="tracks.coord" />
            </address>
          </div>
        </div>
        {/* <div className="bottom-right">
          <FormattedMessage id="tracks.description" />
        </div> */}
      </div>
    </Wrapper>
  );
};

export default injectIntl(Comp);
