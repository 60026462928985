import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 40px 0;
  position: relative;

  .grid {
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-bottom: 50px;
  }

  .mention {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }

  @media ${device.tablet} {
    .grid {
      flex-direction: row;
    }

    .mention {
      display: block;
      font-size: 18px;
      text-align: left;
    }
  }

  @media ${device.laptopM} {
    margin: 80px 0;
  }
`;

export const Block = styled.div`
  position: relative;
  display: flex;
  padding: 10px 10px 40px 10px;
  
  box-sizing: border-box;

  &:last-child {
    padding-bottom: 20px;
  }

  &.spacer {
    &:before {
      right: 30px;
    }
  }

  &:last-child {
    padding-right: 0;
  }

  .wrapper {
    width: 100%;
    box-shadow: 0 18px 22px rgba(191, 191, 191, .5);
    transition: box-shadow .5s ease-in-out;
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;

    &:hover {
      box-shadow: 0px 5px 25px rgba(191, 191, 191, .8);

      .header {
        h4 {
          transform: translate3d(-50%, -65%, 0) skew(-15deg);
        }
      }
    }
  }

  .header {
    position: relative;

    h4 {
      position: absolute;
      top: 0;
      left: 50%;
      margin: 0;
      background-color: #464646;
      color: #FFF;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: -0.02em;
      padding: 7px 15px;
      transform: translate3d(-50%, -50%, 0) skew(-15deg);
      text-align: center;

      transition: transform .4s ease-out;

      &.double {
        line-height: 2.25em;
      }
    }

    .gatsby-image-wrapper {
      width: 100%!important;
    }
  }

  .body {
    margin: 15px 30px 20px;
    text-align: center;

    .headline {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
    }

    .sep {
      font-weight: 900;
      font-style: italic;
      font-size: 18px;
      margin: 10px 0;
    }

    p {
      font-weight: lighter;
      margin-bottom: 0;
      font-size: 15px;
    }
  }

  .footer {
    margin: 15px 30px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
  }

  &.grey {
    &:before {
      background-color: #858585;
    }

    .header {
      h4 {
        background-color: #858585;
      }
    }
  }

  &.red {
    &:before {
      background-color: #E0261D;
    }

    .wrapper {
      border: 3px solid #E0261D;
    }

    .header {
      h4 {
        background-color: #E0261D;
      }
    }
  }

  @media ${device.tablet} {
    width: 25%;
    padding: 60px 10px 50px 0;

    &:last-child {
      padding-right: 0;
      padding-bottom: 50px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #000000;
    }

    .header {
      position: relative;

      h4 {
        font-size: 14px;
        padding: 5px 10px;
        width: 70%;
      }
    }
  }

  @media ${device.laptopM} {
    padding-right: 30px;

    .header {
      position: relative;

      h4 {
        padding: 7px 15px;
        width: auto;
      }
    }
  }

  @media ${device.laptopL} {
    .header {
      h4 {
        font-size: 16px;
      }
    }
  }
`;