import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 40px 0;
  
  .faq {
    margin: 20px 0;
  }


  .accordion-section {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    margin: 10px auto;

    .header {
      cursor: pointer;
      padding: 15px 20px 15px 0px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #000;

      .right {
        margin-left: auto;
        transition: transform .5s ease-in-out;
      }
    }

    .content-wrapper {
      overflow: hidden;
      transition: max-height 0.6s ease;
      box-sizing: border-box;

      .content {
        padding: 15px;
        font-size: 14px;
      }
    }

    &.show {
      .header {
        .right {
          transform: rotate(180deg);
        }
      }
    }
  }

  h4 {
    display: inline-block;
    font-weight: normal;
    margin: 0 0 0 10px;
    vertical-align: bottom;
    font-size: 14px;
  }

  .footer {
    text-align: center;
  }

  @media ${device.tablet} {
    margin: 60px 0;
  
    .faq {
      margin: 40px 0;
    }

    .accordion-section {
      .content-wrapper {
        .content {
          padding: 20px;
          font-size: 16px;
        }
      }
    }

    h4 {
      font-size: 18px;
    }
  }
`;