import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl } from 'gatsby-plugin-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import Title from '../../components/title';

import { Wrapper } from './Pictures.styles';

const QUERY = graphql`
  query {
    first: file(relativePath: { eq: "pictures/first.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2000, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    firstMobile: file(relativePath: { eq: "pictures/first-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 768, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    second: file(relativePath: { eq: "pictures/second.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2000, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    secondMobile: file(relativePath: { eq: "pictures/second-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 768, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    third: file(relativePath: { eq: "pictures/third.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2000, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    thirdMobile: file(relativePath: { eq: "pictures/third-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 768, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    fourth: file(relativePath: { eq: "pictures/fourth.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    fourthMobile: file(relativePath: { eq: "pictures/fourth-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 768, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    fith: file(relativePath: { eq: "pictures/5.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    fithMobile: file(relativePath: { eq: "pictures/5-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    six: file(relativePath: { eq: "pictures/6.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sixMobile: file(relativePath: { eq: "pictures/6-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    seven: file(relativePath: { eq: "pictures/7.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sevenMobile: file(relativePath: { eq: "pictures/7-mobile.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1600, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
  autoplaySpeed: 3000,
  arrows: false,
};

const Comp = (props) => {
  const { intl } = props;
  const data = useStaticQuery(QUERY);
  
  return (
    <Wrapper>
      <Slider {...sliderSettings}>
        <Img
          fluid={[
            data.firstMobile.childImageSharp.fluid,
            {
              ...data.first.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            },
          ]}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <Img
          fluid={[
            data.secondMobile.childImageSharp.fluid,
            {
              ...data.second.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            },
          ]}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <Img
          fluid={[
            data.thirdMobile.childImageSharp.fluid,
            {
              ...data.third.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            },
          ]}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <Img
          fluid={[
            data.fourthMobile.childImageSharp.fluid,
            {
              ...data.fourth.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            },
          ]}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <Img
          fluid={[
            data.fithMobile.childImageSharp.fluid,
            {
              ...data.fith.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            },
          ]}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <Img
          fluid={[
            data.sixMobile.childImageSharp.fluid,
            {
              ...data.six.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            },
          ]}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <Img
          fluid={[
            data.sevenMobile.childImageSharp.fluid,
            {
              ...data.seven.childImageSharp.fluid,
              media: `(min-width: 768px)`,
            },
          ]}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </Slider>
      <div className="container">
        <Title
          className="bbox slider-title"
          title={intl.formatMessage({ id: 'slider.title'})}
        />
      </div>
    </Wrapper>
  );
};

export default injectIntl(Comp);
