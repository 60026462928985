import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Title from '../../components/title';
import { BuyTickets } from '../../components/Typo';

import { Wrapper } from './Rent.styles';

const QUERY = graphql`
  query {
    cover: file(relativePath: { eq: "rent/cover.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 700, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Comp = (props) => {
  const { intl } = props;
  const data = useStaticQuery(QUERY);

  return (
    <Wrapper>
      <div className="container">
        <Title title={intl.formatMessage({ id: 'rent.title' })} />
        <div className="row">
          <div className="illustration col-1">
            <Img
              fluid={data.cover.childImageSharp.fluid}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </div>
          <div className="text-container col-1">
            <FormattedMessage
              id="rent.description"
              values={{
                i: (...chunks) => <i>{chunks}</i>,
                p: (...chunks) => <p>{chunks}</p>,
                ul: (...chunks) => <ul>{chunks}</ul>,
                li: (...chunks) => <li>{chunks}</li>,
                bold: (...chunks) => <strong>{chunks}</strong>,
                h4: (...chunks) => <h4>{chunks}</h4>,
                h5: (...chunks) => <h5>{chunks}</h5>,
                contactmail: (...chunks) => <a href="mailto:info@iponedays.com">{chunks}</a>,
              }}
            />
            <div className="footer">
              <BuyTickets
                href="mailto:info@iponedays.com"
                className="black no-icon"
              >
                <FormattedMessage id="rent.more" />
              </BuyTickets>
              <BuyTickets
                href="mailto:info@iponedays.com"
                className="button skew"
              >
                <FormattedMessage id="rent.book" />
              </BuyTickets>
            </div>
          </div>
        </div>

      </div>
    </Wrapper>
  );
};

export default injectIntl(Comp);
