import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 40px 0;

  @media ${device.tablet} {
    margin: 60px 0;
  }

  .illustration-mobile {
    cursor: pointer;
  
    @media ${device.laptop} {
      display: none;
    }
  }

  .container {
    display: flex;
    flex-direction: column-reverse;

    @media ${device.laptop} {
      flex-direction: row;
      padding-right: 0;
    }
  }

  .text-container {
    flex: 1;
    padding-top: 20px;

    p {
      font-size: 15px;
    }

    .dates {
      display: none;
    }

    @media ${device.laptop} {
      padding-top: 0;
    }
  
    @media ${device.laptopM} {
      p {
        font-size: 16px;
      }

      .dates {
        display: block;
      }
    }
  }

  .dates {
    font-weight: 900;
    font-style: italic;
    font-size: 18px;
    text-transform: uppercase;
    vertical-align: middle;
    margin: 20px 0;

    * {
      vertical-align: middle;
    }

    span {
      margin-left: 10px;
    }
  }

  .cta {
    margin-top: 10px;
  }

  .illustration {
    flex: 2;
    display: none;
    cursor: pointer;

    @media ${device.laptop} {
      display: block;
    }
  }
`;