import React, { useState } from 'react';
import VisibilitySensor from 'react-intersection-visible';
import Vimeo from '@u-wave/react-vimeo';
import styled from 'styled-components';

const Sensor = styled(VisibilitySensor)`
  display: block;
  width: 100%;
  height: 100%;

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export default (props) => {
  const { onPaused } = props;
  const [play, setPlay] = useState(!!props.autoplay);
  const [className, setClassName] = useState('');

  return (
    <Sensor
      className={props.sensorClassName}
      onShow={() => {
        if (!props.autoplay) {
          return;
        }
      
        if (onPaused) {
          onPaused(false);
          return;
        }

        setPlay(true);
      }}
      onHide={() => {
        onPaused ? onPaused(true) : setPlay(false);
      }}
    >
      <Vimeo
        paused={props.paused ?? !play}
        {...props}
        className={`${props.className || ''} ${className || ''}`}
        onReady={() => {
          if (props.readyClassName) {
            setClassName(props.readyClassName);
          }
          props.onReady && props.onReady();
        }}
      />
    </Sensor>
  );
};
