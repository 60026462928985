import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 30px 0;

  @media ${device.tablet} {
    margin: 50px 0;
  }

  .container {
  }


  .illustration {
    padding-bottom: 20px;

    .poster {
      position: relative;
      height: 100%;
    }

    .gatsby-image-wrapper {
      position: absolute!important;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .text-container {
    font-size: 16px;
    font-weight: lighter;

    .description {
      display: none;
    }

    h4 {
      margin-top: 0;
      margin-bottom: 15px;
    }

    ul {
      margin: 0;
      padding-left: 0;
    }

    li {
      list-style: none;
    }

    .stat {
      font-weight: bold;
      font-style: italic;
      font-size: 30px;
      margin-bottom: 5px;

      svg {
        margin-right: 10px;
      }
    }

    .label {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.1em;
    }

    .footer {
      align-items: center;
      text-align: center;

      .key-stats {
        padding-bottom: 10px;
        max-width: 300px;
      }
    }
  }

  .animate-heart {
    animation: heartbeat 2s infinite;
  }

  @media ${device.laptop} {
    .illustration, .text-container {
      padding-bottom: 0;
    }

    .text-container {
      .description {
        display: block;
      }

      .stat {
        font-size: 40px;
      }

      .icon.animate-heart {
        width: 37px!important;
      }

      .label {
        font-size: 15px;
      }

      .footer {
        align-items: flex-end;
        text-align: left;
      
        .col-1 {
          padding-bottom: 0;
        }
      
        .key-stats {
          padding-bottom: 0;
          max-width: none;
          padding-top: 0;
        }
      }
    }
  }

  @media ${device.laptopM} {
    .text-container {
      .description {
        font-size: 18px;
      }

      .label {
        font-size: 20px;
      }

      .footer {
        .key-stats {
          padding-top: 20px;
        }
      }
    }
  }
`;