import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Title from '../../components/title';
import { BuyTickets } from '../../components/Typo';
import { Icon } from '../../components/icons';
import Video from '../../components/Video';

import Heart from '../../images/icons/heart.svg';

import { Wrapper } from './LastYear.styles';

const QUERY = graphql`
  query {
    bikes: file(relativePath: { eq: "lastyear/video-poster.png" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 700, toFormat: JPG) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Comp = (props) => {
  const { intl } = props;
  const data = useStaticQuery(QUERY);

  const [playVideo, setPlayVideo] = useState(false);
  
  return (
    <Wrapper>
      <div className="container">
        <Title title={intl.formatMessage({ id: 'agenda.title' })} />
        <div className="row">
          <div className="illustration col-1">
            <div
              className="poster"
              role="button"
              tabIndex={0}
              onClick={() => setPlayVideo(true)}
            >
              <Video
                video="392664391"
                paused={!playVideo}
                onPaused={(pause) => setPlayVideo(!pause)}
                autopause={false}
                responsive
                showPortrait={false}
                showTitle={false}
                controls={true}
              />
              {!playVideo ? (
                <Img
                  fluid={data.bikes.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="text-container col-1">
            <div className="description">
              <FormattedMessage
                id="agenda.description"
                values={{
                  i: (...chunks) => <i>{chunks}</i>,
                  p: (...chunks) => <p>{chunks}</p>,
                  ul: (...chunks) => <ul>{chunks}</ul>,
                  li: (...chunks) => <li>{chunks}</li>,
                  bold: (...chunks) => <strong>{chunks}</strong>,
                  h4: (...chunks) => <h4>{chunks}</h4>,
                }}
              />
            </div>
            <div className="row footer">
              <div className="col-1 key-stats">
                <div className="stat">
                  <Icon
                    src={Heart}
                    width="30px"
                    className="animate-heart"
                  />
                  98%
                </div>
                <div className="label">
                  <FormattedMessage id="agenda.stat" />
                </div>
              </div>
              <div className="col-1">
                <BuyTickets
                  className="medium"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </Wrapper>
  );
};

export default injectIntl(Comp);
