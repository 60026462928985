import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 40px 0;
  position: relative;

  @media ${device.laptop} {
    margin: 60px 0;
  }

  .container {
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  .title-container {
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: 0;
    }
  }

  .track-container {
    position: absolute;
    bottom: 0;
    left: 0;

    right: 0;
    top: 20%;
    text-align: center;

    .row {
      align-items: center;
    }
  }

  address {
    font-weight: bold;
    font-style: italic;
    color: #FFF;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 10px;

    strong {
      font-size: 23px;
    }
  }

  .bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    max-width: 15rem;
    text-align: right;

    display: none;
  }

  @media ${device.tabletDown} {
    .background-track {
      & > div {
        padding-bottom: 103.52%!important;
      }
    }
  }

  @media ${device.tablet} {
    .container {
      top: 20px;
    }

    .title-container {
      top: 0;
      left: auto;
      align-items: flex-end;
    }
    
    .bottom-right {
      display: block;
      font-size: 16px;
    }

    .track-container {
      right: auto;
      top: auto;

      svg {
        max-width: 140px;
      }
    }
  }
  
  @media ${device.laptop} {
    .container {
      top: 50px;
    }

    .track-container {
      text-align: left;

      svg {
        max-width: none;
      }
    }

    address {
      margin-top: 15px;
    }

    .bottom-right {
      font-size: 18px;
    }
  }

`;