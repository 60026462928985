import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import { Wrapper, Block } from './Offers.styles';
import Title from '../../components/title';

import { BuyTickets } from '../../components/Typo';
 
const QUERY = graphql`
  query {
    motocross: file(relativePath: { eq: "offers/motocross.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 330, toFormat: JPG) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    terrain: file(relativePath: { eq: "offers/terrain.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 330, toFormat: JPG) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    trail: file(relativePath: { eq: "offers/trail.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 330, toFormat: JPG) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    speed: file(relativePath: { eq: "offers/speed.png" }) {
      childImageSharp {
        fixed(quality: 80, width: 330, toFormat: JPG) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

const Item = (props) => (
  <Block className={props.className}>
    <a
      href={props.link}
      target="_blank"
      rel="noopener"
      className="wrapper"
    >
      <div className="header">
        <Img
          fixed={props.img}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        <h4 className={classnames('bbos', {
          'double': props.titleDouble
        })}>
          <span dangerouslySetInnerHTML={{ __html: props.title }} />
        </h4>
      </div>
      <div className="body">
        <div className="headline">{props.headline}</div>
          <div className="sep"><FormattedMessage id="offers.included" /></div>
          <p dangerouslySetInnerHTML={{ __html: props.description }} />
      </div>
      <div className="footer">
        <BuyTickets className="black no-icon"><FormattedMessage id="offers.more" /></BuyTickets>
        <BuyTickets><FormattedMessage id="offers.buy" /></BuyTickets>
      </div>
    </a>
  </Block>
);


const Comp = (props) => {
  const { intl } = props;
  const data = useStaticQuery(QUERY);
  
  return (
    <Wrapper id="sec-offers">
      <div className="container">
        <Title title={intl.formatMessage({ id: 'offers.title' })} />
        <div className="grid">
          <Item
            img={data.motocross.childImageSharp.fixed}
            className="grey spacer"
            title={intl.formatMessage({ id: 'offers.motocross.title' })}
            headline={intl.formatMessage({ id: 'offers.motocross.details' })}
            description={intl.formatMessage({ id: 'offers.motocross.description' })}
            link={intl.formatMessage({ id: 'link' })}
          />
          <Item
            img={data.terrain.childImageSharp.fixed}
            title={intl.formatMessage({ id: 'offers.enduro.title' })}
            headline={intl.formatMessage({ id: 'offers.enduro.details' })}
            description={intl.formatMessage({ id: 'offers.enduro.description' })}
            link={intl.formatMessage({ id: 'link' })}
          />
          <Item
            img={data.trail.childImageSharp.fixed}
            className="spacer"
            title={intl.formatMessage({ id: 'offers.trail.title' })}
            headline={intl.formatMessage({ id: 'offers.trail.details' })}
            description={intl.formatMessage({ id: 'offers.trail.description' })}
            link={intl.formatMessage({ id: 'link' })}
            titleDouble
          />
          <Item
            img={data.speed.childImageSharp.fixed}
            className="red"
            title={intl.formatMessage({ id: 'offers.speed.title' })}
            headline={intl.formatMessage({ id: 'offers.speed.details' })}
            description={intl.formatMessage({ id: 'offers.speed.description' })}
            link={intl.formatMessage({ id: 'link' })}
            titleDouble
          />
        </div>

        <div className="mention">
          <h2><FormattedMessage id="offers.free.title" /></h2>
          <FormattedMessage id="offers.free" /><br />
          <FormattedMessage id="offers.free.tapas" /><br />
          <FormattedMessage id="offers.free.morning" />
        </div>
      </div>
    </Wrapper>
  );
};

export default injectIntl(Comp);
