import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 40px 0;

  @media ${device.tablet} {
    margin: 60px 0;
  }

  .illustration {
    margin: 10px -20px 30px -20px;

    .gatsby-image-wrapper {
      box-shadow: 0 18px 22px rgba(191, 191, 191, 0.5);
    }
  }

  .text-container {
    font-size: 15px;
    font-weight: lighter;

    h4 {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 20px;
    }

    h5 {
      font-size: 16px;
      margin: 20px 0 5px 0;
    }

    ul {
      margin: 0;
      padding-left: 0em;
    }

    li {
      list-style: none;
     
      &:before{
        content: "-";
        display: inline-block;
        margin-right: 5px;
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      a {
        margin: 7px 0;
      }
    }
  }

  @media ${device.tablet} {
    .illustration {
      margin: 0;
    }

    .text-container {
      font-size: 15px;
      padding-bottom: 0;
      padding-top: 30px;

      h4 {
        font-size: 23px;
      }

      h5 {
        font-size: 18px;
      }

      .footer {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 15px;

        .cta {
          margin-left: 20px;
        }
      }
    }
  }

  @media ${device.laptop} {
    .text-container {
      font-size: 14px;
      padding-top: 0;

      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }

  @media ${device.laptopL} {
    .text-container {
      font-size: 18px;

      h4 {
        font-size: 23px;
      }

      h5 {
        font-size: 18px;
      }
    }
  }
`;