import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 40px 0;
  position: relative;

  @media ${device.tablet} {
    margin: 60px 0;
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .slider-title {
    margin-top: 30px;
  }

  @media ${device.tabletDown} {
    .slider-title {
      margin-top: 15px;
      font-size: 15px;
    }

    .gatsby-image-wrapper {
      & > div:first-child {
        padding-bottom: 51.69%!important;
      }
    }
  }
`;